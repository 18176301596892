import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from 'reactstrap';
import "toastr/build/toastr.min.css";
import { Checkbox } from "../../../../components";
import ConfigService from "../../../../components/Config/ConfigService";
import "../../../../components/SuperResponsiveTableStyle.css";
import { DialogContext } from "../../../../store/context/DialogContext";
import useDebounce from "../../../../util/Debounce";

const AppSettingComponent = ({ label, version, setVersion, setData, fieldName, data, type }) => {
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const edit = useDebounce(async (updatedValue) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await ConfigService.edit({
                version: version,
                myRateTypes: updatedValue
            }, "myRateTypes");

            setData(response.myRateTypes);
            setVersion(response.version);
        } catch (error) {
            showError(error);
        }
        setLoading(false);
    }, 600);

    const handleChange = (val) => {
        const updatedValue = { ...data, [fieldName]: val };
        setData(updatedValue);
        edit(updatedValue);
    };

    if (loading) return <Skeleton height={40}/>;
    return (
        <div style={{ maxWidth: '600px' }}>
            {type === 'checkBox' && (
                <div className="mt-3">
                    <div className="d-flex justify-content-between mt-2">
                        <Checkbox
                            checked={data?.[fieldName]}
                            onClick={handleChange}
                            label={label}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const AppSettings = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [version, setVersion] = useState();
    const { showError } = useContext(DialogContext);

    const setNewVersion = useCallback((version) => {
        setVersion(version);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (loading) return;
            setLoading(true);
            try {
                const response = await ConfigService.detail("myRateTypes");
                setData(response.myRateTypes);
                setVersion(response.version);
            } catch (error) {
                showError(error)
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    if (loading) return <Skeleton height={40} className="mb-3" />;

    return (
        <div className="page-content pt-0">
            <Row className="mt-3">
                <Col xs={12} md={6}>
                    <div className="user-settings">
                        <AppSettingComponent
                            label="User Local"
                            type="checkBox"
                            fieldName="USER_LOCAL"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                        <AppSettingComponent
                            label="User Rental"
                            type="checkBox"
                            fieldName="USER_RENTAL"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                        <AppSettingComponent
                            label="User Outstation"
                            type="checkBox"
                            fieldName="USER_OUTSTATION"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                        <AppSettingComponent
                            label="User Transfer"
                            type="checkBox"
                            fieldName="USER_TRANSFER"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                    </div>
                </Col>

                <Col xs={12} md={6}>
                    <div className="admin-settings">
                        <AppSettingComponent
                            label="Admin Local"
                            type="checkBox"
                            fieldName="ADMIN_LOCAL"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                        <AppSettingComponent
                            label="Admin Rental"
                            type="checkBox"
                            fieldName="ADMIN_RENTAL"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                        <AppSettingComponent
                            label="Admin Outstation"
                            type="checkBox"
                            fieldName="ADMIN_OUTSTATION"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                        <AppSettingComponent
                            label="Admin Transfer"
                            type="checkBox"
                            fieldName="ADMIN_TRANSFER"
                            data={data}
                            setData={setData}
                            version={version}
                            setVersion={setNewVersion}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default observer(AppSettings);
