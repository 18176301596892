import React, { useEffect } from "react";
import { InputField } from "../InputField/InputField";
import "./styles.css"
import AmountField from "./AmountField";
const TaxInputField = ({ isDisabled, className, label, value, onChange, isAbs, inputFieldStyle, onChangeAbs = () => { },labelRequired=true }) => {
    return (
        <div
            style={{ position: "relative" }}
            className={className + " "}
        >
            {isAbs ?
                <InputField
                    noLabel={false}
                    value={value}
                    label={labelRequired ? (label ?? "Total Tax"):""}
                    // placeholder={placeholder ?? "Total Tax"}
                    onChange={onChange}
                    showClear={false}
                    type="number"
                    RightComponent={() => (
                        <div
                            onClick={() => {
                                onChangeAbs(!isAbs)
                            }}
                            style={{
                                cursor: "pointer",
                                ...inputFieldStyle
                            }}
                            className=" tax-side-btn d-flex justify-content-center align-items-center">
                            {isAbs ? "%" : "/-"}
                        </div>
                    )}
                />
                :
                <AmountField
                    disabled={isDisabled}
                    placeholder={label ?? "Total Tax"}
                    value={value}
                    onChange={onChange}
                    type="text"
                    RightComponent={() => (
                        <div
                            onClick={() => {
                                onChangeAbs(!isAbs)
                            }}
                            style={{
                                position: "absolute",
                                top: "0px",
                                right: "-30px",
                                cursor: "pointer",
                            }}
                            className=" tax-side-btn   d-flex justify-content-center align-items-center">
                            {isAbs ? "%" : "/-"}
                        </div>
                    )}
                ></AmountField>
            }
        </div>
    );
};

export default TaxInputField;
