import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Card } from "reactstrap";
import {
  CardThreeBounce,
  InputField,
  SelectField,
  TaxInputField,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import TermService from "./TermService";
import { daysBeforeJoiningData, daysBeforeJoiningDataCancellationData } from "./TermConstant";
import RemoveLink from "../../components/Buttons/RemoveLink";
import AddLink from "../../components/Buttons/AddLink";

const EditTerms = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    currency: "INR",
    cancellationSlabs: [{
      label: "Any Time",
      isAbs: true,
      value: 0,
      duration: 0
    }],
    paymentTerms: [{
      label: "At time of booking",
      isAbs: true,
      value: 0,
      paymentEvent: -1
    },
    {
      label: "Before Journey",
      isAbs: true,
      value: 0,
      paymentEvent: 0
    },
    {
      label: "Post Journey",
      isAbs: true,
      value: 0,
      paymentEvent: 99
    }],
  });
  const fetch = async () => {
    try {
      if (editId) {
        let term = await TermService.get(editId);
        setData(term);
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData({
      currency: "INR",
      cancellationSlabs: [{
        label: "Any Time",
        isAbs: true,
        value: 0,
        duration: 0
      }],
      paymentTerms: [{
        label: "At time of booking",
        isAbs: true,
        value: 0,
        paymentEvent: -1
      },
      {
        label: "Before Journey",
        isAbs: true,
        value: 0,
        paymentEvent: 0
      },
      {
        label: "Post Journey",
        isAbs: true,
        value: 0,
        paymentEvent: 99
      }],
    });
    setLoading(true);
    if (editId) fetch();
    setLoading(false);
  }, [editId, TermService?.version]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addPaymentTerm = () => {
    const newData = { ...data };

    const newPaymentTerm = {
      label: "Days before Journey",
      isAbs: true,
      value: 0,
      paymentEvent: null
    };
    newData.paymentTerms.splice(1, 0, newPaymentTerm);
    setData(newData);
  }

  const removePaymentTerm = () => {
    if (data?.paymentTerms?.length <= 3) return;
    const newData = { ...data };
    newData?.paymentTerms?.splice(data?.paymentTerms?.length - 3, 1);
    setData(newData);
  }

  const removeCancellationSlab = () => {
    if (data?.paymentTerms?.length <= 1) return;
    const newData = { ...data };
    newData?.cancellationSlabs?.splice(data?.cancellationSlabs?.length - 1, 1);
    setData(newData);
  }

  const addCancellationSlab = () => {
    const newData = { ...data };

    const newCancellationSlab = {
      label: "Days before Journey",
      isAbs: true,
      value: 0,
      duration: null
    };
    newData?.cancellationSlabs?.splice(1, 0, newCancellationSlab);
    setData(newData);
  }

  return (
    <React.Fragment>
      {loading ? (
        <CardThreeBounce />
      ) : (
        <Form
          className="p-3"
          style={{ height: "100%", padding: "2rem", margin: 0 }}
        >
          <Row
            style={{
              marginTop: 0,
              padding: "1rem",
              borderRadius: "0.5rem",
              fontSize: "12px",
            }}
          >
            <Card className="p-3">
              <InputField
                label={t("Term Name")}
                placeholder={t("Term Name")}
                value={data?.name}
                onChange={(v) => {
                  setData({ ...data, name: v });
                }}
                required={true}
                className="col-sm-12 col-md-4"
              />
            </Card>
            <Card className="p-3">
              <h4 style={{ fontWeight: 600 }}>Payments Terms</h4>
              {data?.paymentTerms?.map((item, pIndex) => {
                return <div className="d-flex align-items-center">
                  <div className="me-2" style={{
                    minWidth: "140px"
                  }}>{item?.label}</div>

                  <div className="col-sm-1">
                    {![-1, 0, 99]?.includes(item.paymentEvent) ? <SelectField
                      outline
                      data={daysBeforeJoiningData}
                      value={item?.paymentEvent}
                      onChange={(v) => {
                        setData((prev) => ({
                          ...prev,
                          paymentTerms: [
                            ...prev.paymentTerms.slice(0, pIndex),
                            {
                              ...prev?.paymentTerms[pIndex],
                              paymentEvent: v?.value
                            },
                            ...prev.paymentTerms.slice(pIndex + 1)
                          ]
                        }));
                      }}
                      label={"Event"}
                    // className="col-sm-6"
                    /> : null}
                  </div>
                  <TaxInputField
                    inputFieldStyle={{
                      position: "absolute",
                      right: "0px"
                    }}
                    labelRequired={false}
                    value={item?.value}
                    onChange={(v) => {
                      setData((prev) => ({
                        ...prev,
                        paymentTerms: [
                          ...prev.paymentTerms.slice(0, pIndex),
                          {
                            ...prev?.paymentTerms[pIndex],
                            value: v
                          },
                          ...prev.paymentTerms.slice(pIndex + 1)
                        ]
                      }));
                    }}
                    isAbs={item?.isAbs}
                    onChangeAbs={(v) => {
                      setData((prev) => ({
                        ...prev,
                        paymentTerms: [
                          ...prev.paymentTerms.slice(0, pIndex),
                          {
                            ...prev?.paymentTerms[pIndex],
                            isAbs: v
                          },
                          ...prev.paymentTerms.slice(pIndex + 1)
                        ]
                      }));
                    }}
                    className="col-sm-3 ms-2"
                  />
                  {pIndex == data?.paymentTerms?.length - 3 &&
                    <div className="ms-2 gap-2 d-flex">
                      <AddLink onClick={addPaymentTerm} size={20} />
                      {pIndex > 0 ?
                        <RemoveLink className="ms-1" onClick={() => removePaymentTerm()} />
                        : null}
                    </div>}
                </div>
              })}
            </Card>
            <Card className="p-3">
              <h4 style={{ fontWeight: 600 }}>Cancellation Slabs</h4>
              {data?.cancellationSlabs?.map((item, pIndex) => {
                return <div className="d-flex align-items-center">
                  <div className="me-2" style={{
                    minWidth: "140px"
                  }}>{item?.label}</div>

                  <div className="col-sm-1">
                    {![0]?.includes(item.duration) ? <SelectField
                      outline
                      data={daysBeforeJoiningDataCancellationData}
                      value={item?.duration}
                      onChange={(v) => {
                        setData((prev) => ({
                          ...prev,
                          cancellationSlabs: [
                            ...prev.cancellationSlabs.slice(0, pIndex),
                            {
                              ...prev?.cancellationSlabs[pIndex],
                              duration: v?.value
                            },
                            ...prev.cancellationSlabs.slice(pIndex + 1)
                          ]
                        }));
                      }}
                      label={"Event"}
                    /> : null}
                  </div>
                  <TaxInputField
                    inputFieldStyle={{
                      position: "absolute",
                      right: "0px",
                    }}
                    labelRequired={false}
                    value={item?.value}
                    onChange={(v) => {
                      setData((prev) => ({
                        ...prev,
                        cancellationSlabs: [
                          ...prev.cancellationSlabs.slice(0, pIndex),
                          {
                            ...prev?.cancellationSlabs[pIndex],
                            value: v
                          },
                          ...prev.cancellationSlabs.slice(pIndex + 1)
                        ]
                      }));
                    }}
                    isAbs={item?.isAbs}
                    onChangeAbs={(v) => {
                      setData((prev) => ({
                        ...prev,
                        cancellationSlabs: [
                          ...prev.cancellationSlabs.slice(0, pIndex),
                          {
                            ...prev?.cancellationSlabs[pIndex],
                            isAbs: v
                          },
                          ...prev.cancellationSlabs.slice(pIndex + 1)
                        ]
                      }));
                    }
                    }
                    className="col-sm-3 ms-2"
                  />
                  {pIndex == data?.cancellationSlabs?.length - 1 &&
                    <div className="ms-2 gap-2 d-flex">
                      <AddLink onClick={addCancellationSlab} size={20} />
                      {pIndex > 0 ?
                        <RemoveLink className="ms-1" onClick={() => removeCancellationSlab()} />
                        : null}
                    </div>}
                </div>
              })}
            </Card>
            <Card className="p-3 pt-1">
              <div>
                <p className="mt-3 mb-1">Cancellation Policy</p>
                <textarea
                  label="Cancellation Policy"
                  className="col-12 mt-0"
                  style={{
                    borderColor: "hsl(0, 0%, 80%)",
                    outline: "none",
                    fontSize: "16px",
                  }}
                  rows="5"
                  placeholder="Cancellation Policy"
                  value={data?.cancellationPolicy}
                  onChange={(e) => {
                    setData({
                      ...data,
                      cancellationPolicy: e.target.value,
                    });
                  }}
                  type="text"
                ></textarea>
              </div>
              <div>
                <p className="mt-3 mb-1">Booking Policy</p>
                <textarea
                  label="Booking Policy"
                  className="col-12 mt-0"
                  style={{
                    borderColor: "hsl(0, 0%, 80%)",
                    outline: "none",
                    fontSize: "16px",
                  }}
                  rows="5"
                  placeholder="Booking Policy"
                  value={data?.bookingPolicy}
                  onChange={(e) => {
                    setData({
                      ...data,
                      bookingPolicy: e.target.value,
                    });
                  }}
                  type="text"
                ></textarea>
              </div>
            </Card>
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default observer(EditTerms);
